import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";

const TermsOfUseSection = () => {
  return (
    <>
      <section className="w-full relative z-20 -mb-10">
        <div className="absolute w-full h-full top-0 left-0 -z-10">
          <img
            className="w-full h-full object-cover"
            srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
            src="/images/page-bg.webp"
            width="1600"
            height="502"
            alt="page-bg"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="container pt-110 lg:pt-185 sc-up-mb relative">
          <div className="text-center">
            <h1 className="heading-3 heading-1-2">
              <Trans>Milkomeda Terms of Use</Trans>
            </h1>
          </div>
          <div className="space-custom"></div>
        </div>

        <div className="w-full -z-10">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            width="1600"
            height="235"
            alt="oval-top"
          />
        </div>
      </section>
      <section className="relative">
        <div className="bg-blue-bg-dark">
          <div className="container z-20 relative">
            <div className="max-w-[720px] mx-auto pb-40 pt-40 xl:pt-20 xl:-mt-60">
              <h2 className="text-22 mb-16 font-semibold">1. Welcome to Milkomeda.com</h2>
              <p className="text-16 text-menu-white mb-16">Effective Date: December 1, 2022</p>
              <p className="text-16 text-menu-white mb-16">
                Milkomeda.com is brought to you by the Milkomeda Foundation (
                <em>
                  <strong>“Milkomeda”</strong>
                </em>
                ) and its affiliates (collectively,{" "}
                <em>
                  <strong>“we,” “us,” or “our”</strong>
                </em>
                ). Milkomeda.com provides information and resources about the fundamentals of the
                decentralized non-custodial blockchain protocol called the Milkomeda Protocol (the{" "}
                <em>
                  <strong>“Milkomeda Protocol”</strong>
                </em>{" "}
                or{" "}
                <em>
                  <strong>“Protocol”</strong>
                </em>
                ).{" "}
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>
                  ARBITRATION NOTICE: THESE TERMS OF USE CONTAIN AN ARBITRATION CLAUSE BELOW. EXCEPT
                  FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT ARBITRATION CLAUSE, YOU AND WE
                  AGREE THAT ANY DISPUTES RELATING TO THE SERVICES (AS DEFINED BELOW) WILL BE
                  RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU WAIVE ANY RIGHT TO A TRIAL BY
                  JURY OR TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.{" "}
                </strong>
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>
                  BY ACCESSING OR USING OUR SERVICES, WHICH INCLUDE OUR VARIOUS WEBSITES, INCLUDING,
                  WITHOUT LIMITATION, MILKOMEDA.COM (AND ANY RESPECTIVE SUBDOMAINS); APPLICATIONS
                  (COLLECTIVELY WITH ANY MATERIALS AND SERVICES AVAILABLE THEREIN, AND SUCCESSOR
                  WEBSITE(S) OR APPLICATION(S) THERETO, THE <em>“SITE”</em>), AND OTHER SERVICES
                  THAT LINK TO THESE TERMS, AS WELL AS ANY INFORMATION, TEXT, LINKS, GRAPHICS,
                  PHOTOS, AUDIO, VIDEO, SOFTWARE, OR OTHER MATERIALS STORED, RETRIEVED OR APPEARING
                  THEREON, WHETHER ACCESSED THROUGH THE SITE OR OTHERWISE (COLLECTIVELY WITH THE
                  SITE, THE <em>“SERVICES”</em>), YOU ARE ENTERING INTO A BINDING AGREEMENT WITH US
                  THAT INCLUDES THESE TERMS OF SERVICE, MILKOMEDA.COM - PRIVACY POLICY, AND OTHER
                  POLICIES REFERENCED IN THESE TERMS OF USE (COLLECTIVELY, THIS <em>“AGREEMENT”</em>
                  ) AND ARE DEEMED TO HAVE READ, ACCEPTED, EXECUTED AND AGREED TO BE BOUND BY THIS
                  AGREEMENT.
                </strong>
              </p>
              <p className="text-16 text-menu-white mb-16">
                Please carefully review this Agreement. To the extent that there is a conflict
                between these Terms of Use and any applicable additional terms, this Agreement will
                control unless expressly stated otherwise. If you don't agree with this Agreement,
                you may not use the Services and should not visit the Site or otherwise engage with
                the Services.
              </p>
              <p className="text-16 font-semibold mb-16">Use of the Services</p>
              <p className="text-16 text-menu-white mb-16">
                To use the Services, you must legally be able to enter into this Agreement. By using
                the Services, you represent and warrant that you meet the eligibility requirements
                stated in this Agreement and that you are legally able to enter into and be bound by
                this Agreement. If you do not meet any such requirements, you must not access or use
                the Site or the other Services.
              </p>
              <p className="text-16 font-semibold mb-16">
                We may update the Services and this Agreement.
              </p>
              <p className="text-16 text-menu-white mb-16">
                We may update the Services, this Agreement, and any part of this Agreement at any
                time, for any reason, at our sole discretion. Once any part of this Agreement is
                updated and in effect, you will be bound by this Agreement if you continue to use
                the Services, including by accessing the Site. We may, at any time, and without
                liability to you, modify or discontinue all or part of the Services (including
                access to the Services via any third-party links). You may contact us with questions
                about your use of the Services at
                <a href="mailto:contact@milkomeda.com">
                  <strong>contact@milkomeda.com</strong>
                </a>
                . When you communicate with us electronically, you consent to receive communications
                from us electronically. You should review this Agreement from time to time to ensure
                that you understand the terms and conditions that apply to you when you access or
                use the Services.
              </p>
              <h2 className="text-22 mb-16 font-semibold">2. Services</h2>
              <p className="text-16 font-semibold mb-16">
                Milkomeda.com is provided as an informational resource.
              </p>
              <p className="text-16 text-menu-white mb-16">
                Milkomeda.com provides resources about the fundamentals of the Milkomeda Protocol,
                which is a fully decentralized, community-driven protocol deployed on multiple
                blockchain networks and systems, and provides information about the wider Milkomeda
                ecosystem, governance, community, and various interfaces and integrations to the
                Milkomeda Protocol. All information provided in connection with your access and use
                the Services is for informational purposes only. You should not take, or refrain
                from taking, any action based on any information contained on the Site or any other
                information that we make available at any time, including blog posts, data,
                articles, links to third-party content, discord content, news feeds, tutorials,
                tweets, and videos. Before you make any financial, legal, technical, or other
                decisions involving the Services, you should seek independent professional advice
                from a licensed and qualified individual in the area for which such advice would be
                appropriate. The Services provide, or third parties may provide, links to other
                sites, applications, or resources. You acknowledge and agree that we are not
                responsible for the availability of such external sites, applications or resources,
                and do not endorse and are not responsible or liable for any content, advertising,
                products, or other materials on or available from such sites or resources. You
                further acknowledge and agree that we will not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be caused by or in
                connection with use of or reliance on any such content, goods, or services available
                on or through any such site or resource. Because the Site provides information about
                the Milkomeda Protocol, this Agreement also provides some information about the use
                of the Protocol. This information is not intended to be comprehensive or address all
                aspects of the Protocol. There is additional documentation on the Site about the
                functioning of the Protocol or its ecosystem or community.
              </p>
              <p className="text-16 font-semibold mb-16">
                Milkomeda and the Milkomeda Protocol ecosystem.
              </p>
              <p className="text-16 text-menu-white mb-16">
                It is important to understand that neither we nor any affiliated entity is a party
                to any transaction on the blockchain networks underlying the Milkomeda Protocol. We
                do not operate or controls the servers that enable the Milkomeda Protocol or any of
                the underlying level-1 blockchain protocols. We do not have possession, custody or
                control over any of your cryptocurrency or other blockchain-based digital assets (
                <strong>
                  <em>“Digital Assets”</em>
                </strong>
                ) or those of other parties appearing on or used in connection with the Services;
                and we do not have possession, custody, or control over any user’s funds. Further,
                we do not store, send, or receive any Digital Assets. You understand that when you
                interact with any Milkomeda Protocol smart contracts, you (and not us) should retain
                control over your Digital Assets at all times. The private key associated with the
                wallet address from which you transfer Digital Assets or the private key associated
                is the only private key that can control the Digital Assets you transfer into the
                smart contracts. You alone are responsible for securing your private keys. We do not
                have access to your private keys. Due to the non-custodial and decentralized nature
                of the technology, we are not intermediaries, agents, advisors, or custodians, and
                we do not have a fiduciary relationship or obligation to you regarding any other
                decisions or activities that you affect when using our Services. You acknowledge
                that we, for the avoidance of doubt, do not have any information regarding any
                users, users’ identities, or services beyond what is available or obtainable
                publicly via the blockchain. We are not responsible for any activities you engage in
                when using Services, and you should understand the risks associated with Digital
                Assets, blockchain technology generally, and our Services.
              </p>
              <p className="text-16 font-semibold mb-16">
                The Milkomeda Protocol is deployed on multiple blockchain-based networks, and we are
                not responsible for the operation of such networks.
              </p>
              <p className="text-16 text-menu-white mb-16">
                The software underlying blockchain networks on which the Milkomeda Protocol is
                deployed, including, for example, the Algorand or Cardano blockchains, are open
                source, which means that anyone can use, utilize, and build on top of it. By using
                the Services, you acknowledge and agree (i) that we are not responsible for the
                operation of the blockchain-based software and networks underlying the Milkomeda
                Protocol, (ii) that there exists no guarantee of the functionality, security, or
                availability of that software and networks, and (iii) that the underlying
                blockchain-based networks are subject to sudden changes in operating rules, such as
                those commonly referred to as “forks”, that can disrupt operations.
              </p>
              <p className="text-16 font-semibold mb-16">
                Transactions on the blockchain are not anonymous.
              </p>
              <p className="text-16 text-menu-white mb-16">
                A widespread belief is that transactions involving blockchains are anonymous. In
                fact, a central feature of blockchains and thus, blockchain-based transactions, are
                that they are transparent. Your public key and your wallet address are visible to
                anyone. To the extent your public key or wallet address can be linked back to you,
                it would be possible for someone to determine your identity and the Digital Assets
                you own.
              </p>
              <p className="text-16 font-semibold mb-16">
                There may be associated blockchain fees.
              </p>
              <p className="text-16 text-menu-white mb-16">
                All transactions using blockchains require the payment of fees, in some cases
                referred to as “gas fees”, which are essentially transaction fees paid on every
                transaction that occurs on the selected blockchain network. Please note that such
                fees are non-refundable. We do not provide any Services to users or deliver, hold,
                and/or receive payment for Digital Assets. We do not receive any fees for any
                transactions, the Services, or the Site.
              </p>
              <p className="text-16 font-semibold mb-16">
                Milkomeda Ecosystem and Community contributors are independent.
              </p>
              <p className="text-16 text-menu-white mb-16">
                All community contributors to the ecosystem around the Milkomeda Protocol are
                independent of us, and we will not have and do not assume any liability or
                responsibility for their actions or omissions.
              </p>
              <h2 className="text-22 mb-16 font-semibold">3. Assumption of Risk</h2>
              <p className="text-16 text-menu-white mb-16">
                You assume the risks of engaging in transactions that rely on smart contracts and
                other experimental technology.
              </p>
              <p className="text-16 text-menu-white mb-16">
                Transactions on the Milkomeda Protocol rely on smart contracts stored on various
                blockchains, cryptographic tokens generated by the smart contracts, and other
                nascent software, applications and systems that interact with blockchain-based
                networks. These technologies are experimental, speculative, inherently risky, and
                subject to change. Among other risks, bugs, malfunctions, cyberattacks, or changes
                to the applicable blockchain (e.g., forks) could disrupt these technologies and even
                result in a total loss of Digital Assets, their market value, or digital funds. You
                are solely responsible for the safekeeping of the private key associated with the
                blockchain address used to interact with the Protocol. We assume no liability or
                responsibility for any such risks. If you are not comfortable assuming these risks,
                you should not access or engage in transactions using blockchain-based technology.
              </p>
              <p className="text-16 text-menu-white mb-16">
                One of the other defining features of blockchain technology is that its entries are
                immutable, which means, as a technical matter, they generally cannot be deleted or
                modified by anyone. This includes Page 4 smart contracts and Digital Assets
                generated and programmed by smart contracts. THUS, TRANSACTIONS RECORDED ON A
                BLOCKCHAIN, INCLUDING TRANSFERS OF DIGITAL ASSETS AND DATA PROGRAMMED INTO THESE
                ASSETS, MUST BE TREATED AS PERMANENT AND CANNOT BE UNDONE BY US OR BY ANYONE. YOU
                MUST BE VERY CAREFUL WHEN YOU FINALIZE ANY TRANSACTION THAT WILL BE RECORDED ON ANY
                BLOCKCHAIN.
              </p>
              <p className="text-16 text-menu-white mb-16">
                We are not liable for any third-party services or links.
              </p>
              <p className="text-16 text-menu-white mb-16">
                We are not responsible for the content or services of any third-party, including,
                without limitation, any network, or apps like Discord, Metamask or Flint, and we
                make no representations regarding the content or accuracy of any third-party
                services or materials.
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>
                  You agree to the automated collection, transfer and disbursement of Digital Assets
                  by smart contracts. You acknowledge and agree that all transactions accessed
                  through the Milkomeda Protocol will be automatically processed using one or more
                  blockchain-based smart contracts. By engaging in transactions using the Services,
                  you acknowledge and consent to the automatic processing of all transactions in
                  connection with using the Services. You further acknowledge and agree that the
                  applicable smart contract will dictate how the funds of a transaction and
                  ownership of Digital Assets are distributed.
                </strong>
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>
                  You acknowledge the risks of using the Services. You bear sole responsibility for
                  evaluating the Services before using them, and all transactions accessed through
                  the Services are irreversible, final, and without refunds. The Services may be
                  disabled, disrupted or adversely impacted as a result of sophisticated
                  cyber-attacks, surges in activity, computer viruses, and/or other operational or
                  technical challenges, among other things. We disclaim any ongoing obligation to
                  notify you of all of the potential risks of using and accessing our Services. You
                  agree to accept these risks and agree that you will not seek to hold any Milkomeda
                  Indemnified Party (defined below) responsible for any consequent losses.
                </strong>
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>
                  We have been involved in developing the software and documentation for the
                  Milkomeda Protocol, licensed under the open source licenses indicated in Github
                  without any licensing fees. Again, we do not operate or controls the servers that
                  run the Milkomeda Protocol or any of the underlying level-1 blockchain protocols,
                  and we do not have possession, custody or control over any of your cryptocurrency
                  or other blockchain-based digital assets. You agree that we will have no liability
                  arising from or relating to your use or access to the Milkomeda Protocol.
                </strong>
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>You are solely responsible for the security of your wallet.</strong> You
                understand and agree that you are solely responsible for maintaining the security of
                your wallet. Any unauthorized access to your wallet by third parties could result in
                the loss or theft of any Digital Asset, or any funds held in your account and any
                associated accounts. You understand and agree that we have no involvement in, and
                you will not hold us responsible for managing and maintaining the security of your
                wallet. You further understand and agree that we are not responsible, and you will
                not hold us accountable, for any unauthorized access to your wallet. It is your
                responsibility to monitor your wallet.
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>
                  We reserve the right to restrict your access from engaging with the Services.
                </strong>{" "}
                For those Services we operate or control, you agree that we have the right to
                restrict your access via any technically available methods if we suspect, in our
                sole discretion, that (a) you are using the Services for money laundering or Page 5
                any illegal activity; (b) you have engaged in fraudulent activity; (c) you have
                acquired Digital Assets using inappropriate methods, including the use of stolen
                funds to purchase such assets; (d) you are the target of any sanctions administered
                or enforced by the U.S. Department of the Treasury’s Office of Foreign Assets
                Control (
                <em>
                  <strong>“OFAC”</strong>
                </em>
                ), the United Nations Security Council, the European Union, Her Majesty’s Treasury,
                or any other legal or regulatory authority in any applicable jurisdiction; (e)
                either you, as an individual or an entity, or your wallet address is listed on the
                Specially Designated Nationals and Blocked Persons List (
                <em>
                  <strong>“SDN List”</strong>
                </em>
                ), Consolidated Sanctions List (
                <em>
                  <strong>“Non-SDN Lists”</strong>
                </em>
                ), or any other sanctions lists administered by OFAC; (f) you are located,
                organized, or resident in a country or territory that is, or whose government is,
                the subject of sanctions, including but not limited to Côte d’Ivoire, Cuba, Belarus,
                Iran, Iraq, Liberia, North Korea, Sudan, and Syria; or (g) you have otherwise acted
                in violation of this Agreement. If we have a reasonable suspicion that you are
                utilizing the Site for illegal purposes, we reserve the right to take whatever
                action we deem appropriate.
              </p>
              <p className="text-16 font-semibold mb-16">
                We do not guarantee the quality or accessibility of the Services.
              </p>
              <p className="text-16 text-menu-white mb-16">
                As a condition to accessing or using the Services or the Site, you acknowledge,
                understand, and agree that from time to time, the Site and the Services may be
                inaccessible or inoperable for any reason, including, but not limited to equipment
                malfunctions, periodic maintenance procedures or repairs, causes beyond our control
                or that we could not reasonably foresee, disruptions and temporary or permanent
                unavailability of underlying blockchain infrastructure or unavailability of
                third-party service providers or external partners for any reason.
              </p>
              <p className="text-16 text-menu-white mb-16">
                You acknowledge and agree that you will access and use the Services, including,
                without limitation, the Site at your own risk. You should not engage in
                blockchain-based transactions unless it is suitable given your circumstances and
                financial resources. By using the Services, you represent that you have been, are
                and will be solely responsible for conducting your own due diligence into the risks
                of a transaction and the underlying smart contracts and Digital Assets.
              </p>
              <h2 className="text-22 mb-16 font-semibold">4. Taxes</h2>
              <p className="text-16 text-menu-white mb-16">
                You are responsible for your taxes and duties. Users bear sole responsibility for
                paying any and all taxes, duties, and assessments now or hereafter claimed or
                imposed by any governmental authority associated with their use of the Services,
                and/or payable as the result of using and/or exploiting any Digital Assets and
                interacting with smart contracts. Blockchain-based transactions are novel, and their
                tax treatment is uncertain.
              </p>
              <h2 className="text-22 mb-16 font-semibold">5. The Services</h2>
              <p className="text-16 text-menu-white mb-16">
                Contingent upon your ongoing compliance with this Agreement, solely to allow you to
                use and enjoy the Services solely as permitted by this Agreement, we grant you a
                personal, worldwide, revocable, nonexclusive and non-assignable license to use the
                software we provide to you as part of our Services, except for the software that we
                license under open source license as indicated in Github.
              </p>
              <p className="text-16 text-menu-white mb-16">
                We own all rights in the Services. We own any and all right, title, and interest in
                and to the Services including, without limitation, any and all copyrights in and to
                any content, code, data, or other materials that we provide and that you may access
                or use on or through the Services. Except as expressly set forth herein, your use of
                or access to the Services does not grant you any ownership or other rights therein.
                Page 6 Any comments, bug reports, ideas, suggestions for improvements, or other
                feedback (collectively,{" "}
                <em>
                  <strong>“Feedback”</strong>
                </em>
                ) that you may provide about our Services are voluntary, unless we beat it out of
                you, which is highly unlikely. Any contribution of Feedback does not and will not
                give or grant you any right, title, or interest in the Services or in any such
                Feedback. You agree that we may use and disclose Feedback in any manner and for any
                purpose whatsoever without further notice or compensation to you and without
                retention by you of any proprietary or other right or claim.
              </p>
              <h2 className="text-22 mb-16 font-semibold">6. Prohibited Content</h2>
              <p className="text-16 text-menu-white mb-16">
                You may only use the Services if you comply with this Agreement (including, without
                limitation, this Agreement), applicable third-party policies, and all applicable
                laws, rules, regulations and related guidance. The following conduct is prohibited:
              </p>
              <p className="text-16 text-menu-white pl-40">
                <ul className="list-disc">
                  <li className="mb-16">
                    using the Services for, or to promote or facilitate, illegal activity
                    (including, without limitation, money laundering, financing terrorism, tax
                    evasion, buying or selling illegal drugs, contraband, counterfeit goods, or
                    illegal weapons);
                  </li>
                  <li className="mb-16">
                    exploiting the Services for any unauthorized commercial purpose;
                  </li>
                  <li className="mb-16">
                    uploading or transmitting viruses, worms, Trojan horses, time bombs, cancel
                    bots, spiders, malware or any other type of malicious code that will or may be
                    used in any way that will affect the functionality or operation of the Services;
                  </li>
                  <li className="mb-16">
                    attempting to or actually copying or making unauthorized use of all or any
                    portion of the Services, including by attempting to reverse compile,
                    reformatting or framing, disassemble, reverse engineer any part of the Services;
                  </li>
                  <li className="mb-16">breading zebras;</li>
                  <li className="mb-16">
                    harvesting or otherwise collecting information from the Services for any
                    unauthorized purpose;
                  </li>
                  <li className="mb-16">
                    using the Services under false or fraudulent pretenses or otherwise being
                    deceitful;
                  </li>
                  <li className="mb-16">
                    interfering with other users’ access to or use of the Services;
                  </li>
                  <li className="mb-16">
                    interfering with or circumventing of the security features of the Services or
                    any third party’s systems, networks or resources used in the provision of
                    Services;
                  </li>
                  <li className="mb-16">
                    engaging in any attack, hack, denial-of-service attack, interference, or exploit
                    of any smart contract in connection with use of the Service (and operations
                    performed by a user that are technically permitted by a smart contract may
                    nevertheless be a violation of our Agreement, including this Agreement, and the
                    law); or
                  </li>
                  <li className="mb-16">
                    engaging in any anticompetitive behavior or other misconduct.
                  </li>
                </ul>
              </p>
              <p className="text-16 text-menu-white mb-16">
                Violating our rules may result in our intervention. You agree and acknowledge that
                if you use the Services to engage in conduct prohibited by applicable law,
                permanently reserve the right to completely or partially restrict or revoke your
                access to the Services to the extent that we can do so, either completely or for a
                period of time, at our sole discretion. We reserve the right to amend, rectify,
                edit, or otherwise alter transaction data to remediate or mitigate any damage caused
                either to us or to any other person as a result of a user’s violation of this
                Agreement or applicable law.
              </p>
              <p className="text-16 text-menu-white mb-16">
                We reserve the right to investigate violations. We reserve the right to investigate
                and prosecute any suspected breaches of this Agreement, including this Agreement. We
                may disclose any information as necessary to satisfy any law, regulation, legal
                process, or governmental request. You acknowledge that two wrongs don’t make a
                right, but three might.
              </p>
              <h2 className="text-22 mb-16 font-semibold">
                7. Disclaimers and Limitations of Liability
              </h2>
              <p className="text-16 text-menu-white mb-16">
                WE MAKE NO REPRESENTATIONS OR WARRANTIES. THE SERVICES ARE PROVIDED ON AN “AS IS”
                AND “AS AVAILABLE” BASIS. WE AND OUR PARENTS, SUBSIDIARIES, AFFILIATES, RELATED
                COMPANIES, AND EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                REPRESENTATIVES, PARTNERS, AND LICENSORS (COLLECTIVELY, THE{" "}
                <strong>
                  <em>“MILKOMEDA INDEMNIFIED PARTIES”</em>
                </strong>
                ) MAKE NO GUARANTEES OF ANY KIND IN CONNECTION WITH THE SERVICES. TO THE MAXIMUM
                EXTENT PERMITTED UNDER APPLICABLE LAW, THE MILKOMEDA INDEMNIFIED PARTIES DISCLAIM
                ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT AND DISCLAIM ALL
                RESPONSIBILITY AND LIABILITY FOR:
              </p>
              <p className="text-16 text-menu-white pl-40">
                <ul className="list-disc">
                  <li className="mb-16">
                    THE SERVICES BEING ACCURATE, COMPLETE, CURRENT, RELIABLE, UNINTERRUPTED, TIMELY,
                    SECURE, OR ERROR-FREE. INFORMATION (INCLUDING, WITHOUT LIMITATION, THE VALUE OR
                    OUTCOME OF ANY TRANSACTION) AVAILABLE THROUGH THE SERVICE IS PROVIDED FOR
                    GENERAL INFORMATION ONLY AND SHOULD NOT BE RELIED UPON OR USED AS THE SOLE BASIS
                    FOR MAKING DECISIONS. ANY RELIANCE ON THE SERVICES IS AT YOUR OWN RISK.
                  </li>
                  <li className="mb-16">
                    INJURY OR DAMAGE RESULTING FROM THE SERVICES. FURTHER, YOU EXPRESSLY ACKNOWLEDGE
                    THAT THE MILKOMEDA INDEMNIFIED PARTIES ARE NOT RESPONSIBLE FOR LOSS OR DAMAGE
                    CAUSED BY ANOTHER USER’S CONDUCT, UNAUTHORIZED ACTORS, OR ANY UNAUTHORIZED
                    ACCESS TO OR USE OF THE SERVICES.
                  </li>
                  <li className="mb-16">
                    VIRUSES, WORMS, TROJAN HORSES, TIME BOMBS, CANCEL BOTS, SPIDERS, MALWARE OR
                    OTHER TYPE OF MALICIOUS CODE THAT MAY BE USED IN ANY WAY TO AFFECT THE
                    FUNCTIONALITY OR OPERATION OF THE SERVICES.
                  </li>
                </ul>
              </p>
              <p className="text-16 text-menu-white mb-16">
                <strong>Limitation of Liability.</strong> TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN
                NO EVENT SHALL ANY MILKOMEDA INDEMNIFIED PARTY BE LIABLE TO YOU FOR ANY LOSS,
                DAMAGE, OR INJURY OF ANY KIND INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE LOSSES OR DAMAGES, OR DAMAGES FOR SYSTEM
                FAILURE OR MALFUNCTION OR LOSS OF PROFITS, DATA, USE, BUSINESS OR GOOD-WILL OR OTHER
                INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH: (A) THE SERVICES OR YOUR
                INABILITY TO USE OR ACCESS THE SERVICES; (B) MISUSE OF THE SERVICES (INCLUDING
                UNAUTHORIZED ACCESS OF THE SERVICES); (C) ANY USER CONDUCT ON THE SERVICES; OR (D)
                TERMINATION, SUSPENSION OR RESTRICTION OF ACCESS TO ANY THE SERVICES.
              </p>
              <p className="text-16 text-menu-white mb-16">
                IN ADDITION TO THE FOREGOING, NO MILKOMEDA INDEMNIFIED PARTY SHALL BE LIABLE FOR ANY
                DAMAGES CAUSED IN WHOLE OR IN PART BY: (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS
                OR INCORRECTLY CONSTRUCTED SMART CONTRACTS OR OTHER TRANSACTIONS; (B) SERVER FAILURE
                OR DATA LOSS; (C) THE MALFUNCTION, UNEXPECTED FUNCTION OR UNINTENDED FUNCTION OF THE
                BLOCKCHAIN, ANY COMPUTER OR CRYPTOASSET NETWORK (INCLUDING ANY WALLET PROVIDER),
                INCLUDING LOSSES ASSOCIATED WITH NETWORK FORKS, REPLAY ATTACKS, DOUBLE-SPEND
                ATTACKS, SYBIL ATTACKS, 51% ATTACKS, GOVERNANCE DISPUTES, MINING DIFFICULTY, CHANGES
                IN Page 8 CRYPTOGRAPHY OR CONSENSUS RULES, HACKING, OR CYBERSECURITY BREACHES; (D)
                ANY CHANGE IN VALUE OF ANY CRYPTOASSET; (E) ANY CHANGE IN LAW, REGULATION, OR
                POLICY; (VI) EVENTS OF FORCE MAJEURE; OR (F) ANY THIRD PARTY.
              </p>
              <p className="text-16 text-menu-white mb-16">
                THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO WHETHER OTHER
                PROVISIONS OF this Agreement HAVE BEEN BREACHED OR HAVE PROVEN INEFFECTIVE. THE
                LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY REGARDLESS OF THE FORM OF ACTION,
                WHETHER THE ASSERTED LIABILITY OR DAMAGES ARE BASED ON CONTRACT, INDEMNIFICATION,
                TORT, STRICT LIABILITY, STATUTE, OR ANY OTHER LEGAL OR EQUITABLE THEORY, AND WHETHER
                OR NOT THE MILKOMEDA INDEMNIFIED PARTIES HAVE BEEN INFORMED OF THE POSSIBILITY OF
                ANY SUCH DAMAGE.
              </p>
              <p className="text-16 text-menu-white mb-16">
                IN NO EVENT WILL THE MILKOMEDA INDEMNIFIED PARTIES’ CUMULATIVE LIABILITY TO YOU OR
                ANY OTHER USER, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY EXCEED ONE
                HUNDRED U.S. DOLLARS (U.S. $100.00).
              </p>
              <p className="text-16 text-menu-white mb-16">
                UNDER NO CIRCUMSTANCES SHALL ANY MILKOMEDA INDEMNIFIED PARTY BE REQUIRED TO DELIVER
                TO YOU ANY DIGITAL ASSETS AS DAMAGES, MAKE SPECIFIC PERFORMANCE, OR ANY OTHER
                REMEDY. IF YOU WOULD BASE YOUR CALCULATIONS OF DAMAGES IN ANY WAY ON THE VALUE OF
                DIGITAL ASSETS, YOU AND WE AGREE THAT THE CALCULATION SHALL BE BASED ON THE LOWEST
                VALUE OF THE DIGITAL ASSETS DURING THE PERIOD BETWEEN THE ACCRUAL OF THE CLAIM AND
                THE AWARD OF DAMAGES.
              </p>
              <p className="text-16 text-menu-white mb-16">
                Some jurisdictions do not allow the exclusion or limitation of certain warranties
                and liabilities provided in this section; accordingly, some of the above limitations
                and disclaimers may not apply to you. If applicable law does not permit Milkomeda
                Indemnified Parties to disclaim certain warranties or limit certain liabilities,
                Milkomeda Indemnified Parties’ limitations on liability and disclaimers of
                warranties will apply to the full extent permitted under applicable law.
              </p>
              <h2 className="text-22 mb-16 font-semibold">8. Indemnification</h2>
              <p className="text-16 text-menu-white mb-16">
                You agree to indemnify, defend, and hold harmless the Milkomeda Indemnified Parties
                from any claim or demand, including reasonable attorneys’ fees, made by any third
                party due to or arising out of: (a) your breach or alleged breach of this Agreement
                (including, without limitation, this Agreement); (b) anything you contribute to the
                Services; (c) your misuse of the Services, or any smart contract and/or script
                related thereto; (d) your violation of any laws, rules, regulations, codes,
                statutes, ordinances, or orders of any governmental or quasi-governmental
                authorities; (e) your violation of the rights of any third party, including any
                intellectual property right, publicity, confidentiality, property, or privacy right;
                (f) your use of a third-party product, service, and/or website; or (g) any
                misrepresentation made by you. We reserve the right to assume, at your expense, the
                exclusive defense and control of any matter subject to indemnification by you. You
                agree to cooperate with our defense of any claim. You will not in any event settle
                any claim without our prior written consent.
              </p>
              <h2 className="text-22 mb-16 font-semibold">
                9. Arbitration Agreement and Waiver of Rights, Including Class Actions.
              </h2>
              <p className="text-16 text-menu-white mb-16">
                PLEASE READ THIS SECTION CAREFULLY: IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS,
                INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT AND TO HAVE A JURY HEAR YOUR CLAIMS.
                IT CONTAINS PROCEDURES FOR MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.
              </p>
              <p className="text-16 text-menu-white mb-16 underline">
                Agreement to Attempt to Resolve Disputes Through Good Faith Negotiations
              </p>
              <p className="text-16 text-menu-white mb-16">
                Prior to commencing any legal proceeding against us of any kind, including an
                arbitration as set forth below, you and we will attempt to resolve any dispute,
                claim, or controversy between us arising out of or relating to this Agreement or the
                Services (each, a{" "}
                <em>
                  <strong>“Dispute”</strong>
                </em>{" "}
                and, collectively,{" "}
                <em>
                  <strong>“Disputes”</strong>
                </em>
                ) by engaging in good faith negotiations. Such good faith negotiations require, at a
                minimum, that the aggrieved party provide a written notice to the other party
                specifying the nature and details of the Dispute. The party receiving such notice
                shall have thirty (30) days to respond to the notice. Within sixty (60) days after
                the aggrieved party sent the initial notice, the parties shall meet and confer in
                good faith by videoconference, or by telephone, to try to resolve the Dispute. If
                the parties are unable to resolve the Dispute within ninety (90) days after the
                aggrieved party sent the initial notice, the parties may agree to mediate their
                Dispute, or either party may submit the Dispute to arbitration as set forth below.
              </p>
              <p className="text-16 text-menu-white mb-16 underline">Agreement to Arbitrate</p>
              <p className="text-16 text-menu-white mb-16">
                You and we agree that any Dispute that cannot be resolved through the procedures set
                forth above will be resolved through binding arbitration in accordance with the
                International Arbitration Rules of the International Centre for Dispute Resolution.
                The place of arbitration shall be the Cayman Islands. The language of the
                arbitration shall be English. The arbitrator(s) shall have experience adjudicating
                matters involving Internet technology, software applications, financial transactions
                and, ideally, blockchain technology. The arbitrator’s award of damages must be
                consistent with the terms of the{" "}
                <em>
                  <strong>“Limitation of Liability”</strong>
                </em>{" "}
                subsection of this Agreement as to the types and amounts of damages for which a
                party may be held liable. The prevailing party will be entitled to an award of their
                reasonable attorney’s fees and costs. Except as may be required by law, neither a
                party nor its representatives may disclose the existence, content, or results of any
                arbitration hereunder without the prior written consent of (all/both) parties.
              </p>
              <p className="text-16 text-menu-white mb-16">
                UNLESS YOU TIMELY PROVIDE US WITH AN ARBITRATION OPT-OUT NOTICE (AS DEFINED BELOW IN
                THE SUBSECTION TITLED{" "}
                <em>
                  <strong>“YOUR CHOICES”</strong>
                </em>
                ), YOU ACKNOWLEDGE AND AGREE THAT YOU AND WE ARE EACH WAIVING THE RIGHT TO A TRIAL
                BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                ACTION OR REPRESENTATIVE PROCEEDING. FURTHER, UNLESS BOTH YOU AND WE OTHERWISE AGREE
                IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS AND MAY
                NOT OTHERWISE PRESIDE OVER ANY FORM OF ANY CLASS OR REPRESENTATIVE PROCEEDING.
              </p>
              <p className="text-16 text-menu-white mb-16 underline">Changes</p>
              <p className="text-16 text-menu-white mb-16">
                By rejecting any changes to this Agreement, you agree that you will arbitrate any
                Dispute between you and us in accordance with the provisions of this section as of
                the date you first accepted this Agreement (or accepted any subsequent changes to
                this Agreement).
              </p>
              <h2 className="text-22 mb-16 font-semibold">
                10. Waiver of Injunctive or Other Equitable Relief.
              </h2>
              <p className="text-16 text-menu-white mb-16">
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AGREE THAT YOU WILL NOT BE PERMITTED TO
                OBTAIN AN INJUNCTION OR OTHER EQUITABLE RELIEF OF ANY KIND, SUCH AS ANY COURT OR
                OTHER ACTION THAT MAY INTERFERE WITH OR PREVENT THE DEVELOPMENT OR EXPLOITATION OF
                THE SERVICES, OR ANY OTHER WEBSITE, APPLICATION, CONTENT, SUBMISSION, PRODUCT,
                SERVICE, OR INTELLECTUAL PROPERTY OWNED, LICENSED, USED OR CONTROLLED BY ANY
                MILKOMEDA INDEMNIFIED PARTY.
              </p>
              <h2 className="text-22 mb-16 font-semibold">11. Termination; Cancellation</h2>
              <p className="text-16 text-menu-white mb-16">
                This Agreement is effective unless and until terminated by either you or us. You may
                terminate your Agreement with us at any time by ceasing all access to the Site or
                the Services. If, in our sole judgment, you fail, or we suspect that you have
                failed, to comply with any term or provision of this Agreement (including any
                provision of this Agreement), we reserve the right to terminate our Agreement with
                you and deny you access to the Services. We further reserve the right to restrict
                your access to the Site or to stop providing you with all or a part of the Services
                at any time and for no reason, including, without limitation, if we reasonably
                believe: (a) your use of the Services exposes us to risk or liability; (b) you are
                using the Services for unlawful purposes; or{" "}
                <span className="font-variant-none">(c)</span> it is not commercially viable to
                continue providing you with our Services. All of these are in addition to any other
                rights and remedies that may be available to us, whether in equity or at law, all of
                which we expressly reserve.
              </p>
              <p className="text-16 text-menu-white mb-16">
                WE RESERVE THE RIGHT TO MODIFY THE SERVICES AT ANY TIME, BUT WE HAVE NO OBLIGATION
                TO DO SO. YOU AGREE THAT IT IS YOUR RESPONSIBILITY TO MONITOR CHANGES TO THE
                SERVICES THAT MAY AFFECT YOU. YOU AGREE THAT WE MAY REMOVE THE SERVICES AND/OR ANY
                CONTENT THEREON FOR INDEFINITE PERIODS OF TIME OR CANCEL THE SERVICES AT ANY TIME,
                WITHOUT NOTICE TO YOU.
              </p>
              <h2 className="text-22 mb-16 font-semibold">12. Severability</h2>
              <p className="text-16 text-menu-white mb-16">
                If any provision of this Agreement (including, without limitation, this Agreement)
                is determined to be unlawful, void, or unenforceable, such provision shall
                nonetheless be enforceable to the fullest extent permitted by applicable law, and
                the unenforceable portion shall be deemed to be severed from this Agreement. Such
                determination shall not affect the validity and enforceability of any other
                remaining provisions.
              </p>
              <h2 className="text-22 mb-16 font-semibold">13. Assignment</h2>
              <p className="text-16 text-menu-white mb-16">
                This Agreement (including, without limitation, this Agreement) may be assigned
                without your prior consent to any Milkomeda Indemnified Party, or to its successors
                in the interest of any business associated with the Services provided by us. You may
                not assign or transfer any rights or obligations under this Agreement without our
                prior written consent.
              </p>
              <h2 className="text-22 mb-16 font-semibold">14. Entire Agreement</h2>
              <p className="text-16 text-menu-white mb-16">
                This Agreement (including, without limitation, the Milkomeda Privacy Policy) and any
                policies or operating rules posted by us on the Services constitute the entire
                agreement and understanding between you and us and govern your use of the Services,
                superseding any prior or contemporaneous agreements, communications, and proposals,
                whether oral or written, between you and us (including, but not limited to, any
                prior versions of this Agreement). Any failure by us to exercise or enforce any
                right or provision of this Agreement (including, without limitation, this Agreement)
                shall not constitute a waiver of such right or provision.
              </p>
              <h2 className="text-22 mb-16 font-semibold">15. Governing Law</h2>
              <p className="text-16 text-menu-white mb-16">
                This Agreement and any separate agreements whereby we provide you Services shall be
                governed by and construed in accordance with the laws of the Cayman Islands.
              </p>
              <h2 className="text-22 mb-16 font-semibold">16. Interpretation</h2>
              <p className="text-16 text-menu-white mb-16">
                The word “or” as used in these Terms of Use has an inclusive meaning, equivalent to
                “and/or”. The terms ‘include’ and ‘including’ will be deemed to be immediately
                followed by the phrase “without limitation.” The headings appearing at the beginning
                of several sections contained in these Terms of Use have been inserted for
                identification and reference purposes only and must not be used to construe or
                interpret these Terms of Use. The word “will” as used in these Terms of Use has its
                common meaning, as well as the meaning ascribed to the word “shall”: expressing a
                current obligation, or obligation that will arise in the future. Defined terms in
                the singular include the plural and vice versa. Words in the masculine gender
                include the feminine and neuter gender and vice versa. Words such as "herein,"
                "hereinafter," "hereto," "hereby," and "hereunder," when used in these Terms of Use,
                refer to these Terms of Use as a whole, unless the context otherwise requires.
              </p>
              <p className="text-16 text-menu-white text-center">~ End ~</p>
            </div>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfUseSection;
